import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.showLoaderPopup)
    ? (_openBlock(), _createBlock($setup["FileLoaderModal"], {
        key: 0,
        show: $setup.showLoaderPopup,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showLoaderPopup) = $event)),
        title: $props.title,
        text: $props.text,
        types: $props.types,
        onMaskClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
        onUploadFile: $setup.uploadFile
      }, null, 8, ["show", "title", "text", "types"]))
    : ($setup.showCropPopup)
      ? (_openBlock(), _createBlock($setup["ImageCropperModal"], {
          key: 1,
          show: $setup.showCropPopup,
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (($setup.showCropPopup) = $event)),
          file: $setup.file,
          compressFile: "",
          aspectRatio: $props.aspectRatio,
          description: $props.description,
          onMaskClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close'))),
          onFileUploaded: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('fileUploaded', $event)))
        }, null, 8, ["show", "file", "aspectRatio", "description"]))
      : _createCommentVNode("", true)
}