import { CreditAmountEnum } from "@/enums/customer/CreditAmountEnum";
import { CreditTermsEnum } from "@/enums/customer/CreditTermsEnum";

export const creditTermsOptions = [
  {
    value: CreditTermsEnum.Prepay,
    label: "Prepay",
  },
  {
    value: CreditTermsEnum.HalfPrepayHalfNet30,
    label: "50% Prepay | 50% Net +30 days",
  },
  {
    value: CreditTermsEnum.Net30,
    label: "Net +30 days",
  },
];

export const creditAmountOptions = [
  {
    value: CreditAmountEnum.TEN,
    label: "$ 10K",
  },
  {
    value: CreditAmountEnum.TWENTY_FIVE,
    label: "$ 25K",
  },
  {
    value: CreditAmountEnum.FIFTY,
    label: "$ 50K",
  },
  {
    value: CreditAmountEnum.ONE_HUNDRED,
    label: "$ 100K",
  },
  {
    value: CreditAmountEnum.TWO_HUNDRED_FIFTY,
    label: "$ 250K",
  },
  {
    value: CreditAmountEnum.FIVE_HUNDRED,
    label: "$ 500K",
  },
];
