import {
  incorrectLinkMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const linkValidationRules = (
  field: string,
  required = true,
  withTrigger = true,
  key = ""
): FormItemRule[] => {
  return [
    {
      key,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!value) {
            if (required) {
              reject(Error(requiredMessage(field)));
            } else {
              resolve();
            }
          } else if (
            // eslint-disable-next-line no-useless-escape
            !/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/i.test(
              value
            )
          ) {
            reject(Error(incorrectLinkMessage));
          } else {
            resolve();
          }
        });
      },
      trigger: withTrigger ? ["input", "blur"] : [],
    },
  ];
};

export default linkValidationRules;
