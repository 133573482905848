export const setDeepObjectValue = (
  obj = {},
  paths = [],
  value = ""
): Record<string, string> => {
  const inputObj: Record<string, string> = { ...obj };

  if (paths.length === 0) {
    return inputObj;
  }

  if (paths.length === 1) {
    return { ...inputObj, [paths[0]]: value };
  }

  const [path, ...rest] = paths;
  const currentNode = inputObj[path];
  const childNode = setDeepObjectValue(currentNode, rest, value);

  return { ...inputObj, [path]: childNode };
};

export const getDeepObjectValue = (obj = {}, paths = []): string => {
  if (paths.length === 1) {
    return obj[paths[0]];
  }
  return getDeepObjectValue(obj[paths[0]], paths.slice(1));
};
