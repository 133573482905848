import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8048c9e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "company-editable-logo--buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["company-editable-logo--upload", {relative: $props.logoId}])
  }, [
    _createVNode($setup["Loader"], { loading: $setup.logoLoading }, null, 8, ["loading"]),
    ($setup.showUploadPopup)
      ? (_openBlock(), _createBlock($setup["ImageLoaderModalWithCrop"], {
          key: 0,
          title: "Add company logo",
          text: "Please add a logo in one of these formats: .png or .jpeg",
          onClose: $setup.closeUploadPopup,
          onFileUploaded: $setup.uploadLogo
        }))
      : _createCommentVNode("", true),
    ($props.logoId?.originalUploadId)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "company-editable-logo--image",
          src: $props.logoId.originalUploadId.link
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "company-editable-logo--image company-editable-logo--image-default",
          src: require('@/assets/placeholders/customers/customer-thumbnail-placeholder.svg')
        }, null, 8, _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        ($props.logoId)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: $setup.removeLogo
            }, "Remove"))
          : _createCommentVNode("", true),
        _createElementVNode("button", { onClick: $setup.openUploadPopup }, _toDisplayString($props.logoId ? "Change" : "Upload"), 1)
      ])
    ])
  ], 2))
}