import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { CustomerChangeParamsType } from "@/types/customer/CustomerChangeParamsType";

export default class CustomerService {
  static uploadCustomerLogo(params: {
    id: string;
    logoId: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/customer/${params.id}/logo`, params);
  }

  static deleteCustomerLogo(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/customer/${id}/logo`);
  }

  static getCustomerDetails(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/customer/${id}`);
  }

  static changeCompanyAccount(
    id: string,
    payload: Record<string, unknown>
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/customer/${id}/account`, payload);
  }

  static getCompanyMergeStats(
    sourceId: string,
    targetId: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/customer/merge", {
      params: { sourceId, targetId },
    });
  }

  static mergeCompanyAccounts(
    sourceId: string,
    targetId: string,
    keepOwners: boolean
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/customer/merge", null, {
      params: {
        sourceId,
        targetId,
        keepOwners,
      },
    });
  }
}
