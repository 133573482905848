import { renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "main-modal upload-modal",
    preset: "dialog",
    "show-icon": false,
    title: $props.title,
    types: $props.types
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode($setup["FileLoaderDropZone"], {
          text: $props.text,
          types: $props.types,
          onUploadFile: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('uploadFile', $event)))
        }, null, 8, ["text", "types"])
      ])
    ]),
    _: 3
  }, 8, ["title", "types"]))
}